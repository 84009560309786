<template>
    <div class="Privacy">
      <navBar></navBar>
      <textEdit title-user="Privacy" title-intern-prop="privacy" site-prop="privacy"></textEdit>
    </div>
</template>

<script>
  import navBar from '@/components/NavBar.vue';
  import textEdit from '@/components/TextEdit.vue';
    export default {
      data: function () {
        return{

        }
      },
      methods: {

      },
      components: {
        navBar,
        textEdit
      },
    };
</script>

<style scoped>

</style>
